button {
    border: none;
}

.content-Mytabs {
    flex-grow: 1;
}

.content-t {
    background: white;
    padding: 20px;
    width: 100%;
    height: 100%;
    display: none;
}

    .content-t h2 {
        padding: 0px 0 5px 0px;
    }


    .content-t p {
        width: 100%;
        height: 100%;
    }

.active-content-t {
    display: block;
}
